import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_h = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - H'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>H</h2>
     <LatQuotesIntro />
    
     <p><b>Habeas corpus</b> - That you have the body.</p>
<p><b>Habemus optimum testem confitentem reum</b> - We have the best witness, a confessing defendant.</p>
<p><b>Haeredem est nomen collectum</b> - Heir is a collective name.</p>
<p><b>Haeres est nomen juris, filius est nomen naturae</b> - Heir is a term of law, son, one of nature.</p>
<p><b>Haeres legitimus est quem nuptiae demonstrant</b> - He is the lawful heir whom the marriage indicates.</p>
<p><b>Homo vocabulum est naturae; persona juris civilis</b> - Man is a term of nature, person of the civil law.</p>

   </Layout>
  )
}

export default LatQuotes_h
